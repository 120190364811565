Drupal.behaviors.gnavCart = (function ($, _, site, generic, Mustache) {
  'use strict';

  // Private variables:
  var $html = $();
  var $container = $();
  var $cartContent = $();
  var $counterInIcon = $();
  var $trigger = $();
  var $cartTitleCounter = $();
  var $cartLinkTrigger = $();
  var $cartSubtotal = $();
  var $carContentItems = $();
  var $carContentNoItems = $();
  var $cartProducts = $();
  var $cartLinkRemove = $();
  var $cartNotification = $();
  var $cartNotificationContent = $();
  var $productRow = $();
  var $triggerResetLabel = $();
  var $headerMain;
  var itemAddedMessage;
  var emptyBagMessage;
  var isGnavCartHovered = false;
  var closeCart = false;
  var data = {
    item_count: parseInt(site.userInfoCookie.getValue('item_count')) || 0,
    subtotal: '',
    points: 0,
    order_within: '',
    ship_sooner_date: '',
    items: [],
    new_items: []
  };
  var state = 'empty';
  var loaded = false;
  var bps = Unison.fetch.all();
  var bp = Unison.fetch.now();
  var isMobile = parseInt(bp.width, 10) < parseInt(bps.landscape, 10);

  // Replace dots in the top-level key names the server is giving us.
  // 'prod.PROD_RGN_NAME' --> 'prod_PROD_RGN_NAME'
  function _normalizeResponseKeys(items) {
    var out = [];
    var replaceKey = function (key) {
      return key.replace(/\./, '_');
    };

    for (var i = 0, len = items.length; i < len; i++) {
      out[i] = {};
      for (var key in items[i]) {
        if (Object.prototype.hasOwnProperty.call(items[i], key)) {
          out[i][replaceKey(key)] = items[i][key];
        }
      }
    }

    return out;
  }

  function _setCloseTimeout() {
    if (state !== 'added') {
      return;
    }

    setTimeout(function () {
      if (closeCart && !isGnavCartHovered) {
        behavior.closeCartOverlay();
        closeCart = false;
      }
      behavior.setState();
    }, 5000);
  }

  // Public methods:
  var behavior = {
    $productRowTemplate: $(),
    attach: function (context) {
      $html = $('html');
      $container = $container.add($('.js-gnav-util-cart', context)).first();
      $cartContent = $cartContent.add($('.js-gnav-util-cart-content', context)).first();
      $counterInIcon = $counterInIcon.add($('.js-gnav-util-cart-count', context));
      $trigger = $('#gnav_util_trigger_cart', context);
      $cartTitleCounter = $cartTitleCounter.add(
        $('.js-gnav-util-content-header-title-counter', context)
      );
      $cartLinkTrigger = $cartLinkTrigger.add($('.js-gnav-util-trigger--cart', context));
      $cartSubtotal = $cartSubtotal.add(
        $('.js-gnav-util-cart-content-products-bottom-subtotal-details-value', context)
      );
      $cartProducts = $cartProducts.add($('.js-gnav-util-cart-content-products-details', context));
      $carContentItems = $carContentItems.add($('.js-gnav-util-cart-content-products', context));
      $carContentNoItems = $carContentNoItems.add(
        $('.js-gnav-util-cart-content-products-no-items', context)
      );
      $cartLinkRemove = $cartLinkRemove.add($('.js-product-row-info-remove', context));
      $cartNotification = $cartNotification.add($('.js-gnav-util-cart-notification', context));
      $cartNotificationContent = $cartNotificationContent.add(
        $('.js-gnav-util-cart-notification-content', context)
      );
      $triggerResetLabel = $('.js-gnav-util-cart-trigger-reset', $container);
      itemAddedMessage = $('.js-gnav-util-cart-success_msg', context).data('message');
      emptyBagMessage = $('.js-gnav-util-cart-empty-bag', context).data('message');
      $headerMain = $('.site-header__wrapper', context);

      $cartLinkTrigger
        .off('click.siteHeader touchstart.siteHeader')
        .on('touchstart.siteHeader', function () {
          if (!isMobile) {
            $(this).trigger('click');
          }
        })
        .on('click.siteHeader', function (e) {
          e.preventDefault();
          window.location = $(this).attr('href');
        });

      // Close utility overlay when moving the mouse out or clicking outside of it:
      $html.once().on(
        'click mouseout mouseover',
        _.debounce(function (event) {
          if (
            $html.hasClass('active-utility-overlay') &&
            ((event.type === 'mouseout' && !event.relatedTarget) ||
              !$(event.target).parents('.site-header-formatter__header').length)
          ) {
            behavior.closeCartOverlay();
          }
        }, 300)
      );

      $triggerResetLabel
        .off('mouseover.siteHeader click.siteHeader')
        .on('mouseover.siteHeader', function () {
          var $this = $(this);

          if ($this.hasClass('site-header-formatter-overlay')) {
            // Close overlay after 3.3 seconds because when a product is added to the cart,
            // the cart overlay is opened and closes very fast because the cursor is outside the cart content.
            setTimeout(function () {
              behavior.closeCartOverlay();
            }, 3330);
          }
        });

      // Load cart counter first time
      behavior.updateCartCounter(data.item_count);
    },

    render: function () {
      var self = this;
      var itemsLength = data.items.length;
      var $productRow = $('.js-product-row', document);
      var productRow = {};
      var productRowTarget = {};
      var productRowTargetRender = '';
      var rendered = '';

      // get $productRow raw template and store
      if (self.$productRowTemplate.length === 0) {
        self.$productRowTemplate = $productRow;
      }

      $cartProducts.html('');
      // If $productRow does not exist then return
      if ($productRow.length === 0) {
        return;
      }
      for (var i = 0, len = itemsLength; i < len; i += 1) {
        productRow = self.$productRowTemplate.clone();
        productRowTarget = $(productRow)[0];
        productRowTargetRender = productRowTarget.outerHTML;
        rendered = Mustache.render(productRowTargetRender, data.items[i]);

        $cartProducts.append(rendered);
      }

      // Update the Subtotal
      $cartSubtotal.text(data.subtotal);
      // Update View Bag
      $cartTitleCounter.text(data.item_count);
      behavior.updateCartCounter(data.item_count);

      return this;
    },

    load: function (force) {
      if (loaded && (!_.isBoolean(force) || !force)) {
        return this;
      }

      generic.jsonrpc.fetch({
        method: 'trans.get',
        params: [
          {
            trans_fields: ['TRANS_ID', 'totals'],
            payment_fields: [],
            order_fields: ['items', 'samples', 'offerCodes']
          }
        ],
        onSuccess: function (response) {
          var value = response.getValue();

          $container.removeClass('cart-loading');
          if (_.isUndefined(value) || !value) {
            $container.addClass('cart-block--empty');

            return;
          }
          var cartItems = value.order.items.concat(value.order.samples);

          behavior.setData({
            subtotal: value.formattedSubtotal,
            points: value.points,
            order_within: '', // This was removed from the designs
            ship_sooner_date: '', // This was removed from the designs
            item_count: value.items_count,
            items: _normalizeResponseKeys(cartItems)
          });
          if (!isMobile) {
            behavior.setOverlayHeight();
          }
        },
        onFailure: function (jsonRpcResponse) {
          var errors = jsonRpcResponse.getMessages();
          var resultData = jsonRpcResponse.getValue();

          $(document).trigger('getItemsCart.failure', [errors, resultData]);
          $container.removeClass('loading');
          loaded = false;
        }
      });

      // Don't put loaded in success function! That allows the user to fire
      // additonal requests while the first is still loading.
      loaded = true;

      return this;
    },

    addItem: function (result) {
      var itemAddedMessage = $('.js-gnav-util-cart-success_msg', document).data('message');

      if (
        _.isUndefined(result) ||
        !result ||
        _.isUndefined(result.trans_data) ||
        _.isUndefined(result.ac_results)
      ) {
        return this;
      }

      var addedItems = '';

      addedItems = _.map(result.ac_results, function (value) {
        var res = value.result;
        var item = res.CARTITEM;

        if (item) {
          // Seems very dumb to calculate this on the front end.
          item.new_qty = Math.max(1, item.ITEM_QUANTITY - res.PREVIOUS_ITEM_QUANTITY);

          return item;
        }
      });

      this.setData({
        subtotal: result.trans_data.formattedSubtotal,
        points: result.trans_data.points === 0 ? 0 : result.trans_data.points || data.points,
        items: _normalizeResponseKeys(result.trans_data.order.items),
        item_count: result.trans_data.items_count,
        new_items: _normalizeResponseKeys(addedItems)
      });

      // Temporarily set the added state:
      this.setState('added');
      state = 'added';
      closeCart = true;
      loaded = true;
      // After add a product then display the cart Overlay.
      if (isMobile) {
        behavior.displayNotificationMobile(itemAddedMessage);
      } else {
        behavior.openCartOverlay();
      }
      _setCloseTimeout();

      return this;
    },

    removeItem: function ($item) {
      var params = {
        _SUBMIT: 'cart',
        SKU_BASE_ID: $item.data('sku-base-id'),
        CART_ID: $item.data('cart-id'),
        QTY: 0
      };

      generic.jsonrpc.fetch({
        method: 'rpc.form',
        params: [params],
        onSuccess: function (response) {
          $container.removeClass('cart-loading');
          var value = response.getData().trans_data;

          if (_.isUndefined(value) || !value) {
            return;
          }
          var cartItems = value.order.items.concat(value.order.samples);

          behavior.setData({
            subtotal: value.formattedSubtotal,
            points: value.points,
            item_count: value.items_count,
            items: _normalizeResponseKeys(cartItems)
          });
          behavior.setOverlayHeight();
        },
        onFailure: function (jsonRpcResponse) {
          var errors = jsonRpcResponse.getMessages();
          var resultData = jsonRpcResponse.getData();

          $(document).trigger('removeToCart.failure', [errors, resultData]);
        }
      });
    },

    addOffer: function (result) {
      if (
        _.isUndefined(result) ||
        !result ||
        _.isUndefined(result.trans) ||
        _.isUndefined(result.items)
      ) {
        return this;
      }
      // var resultType = this.getResultType(result.ac_results);

      // var addedItems = '';
      // addedItems = _.map(result.ac_results, function(value) {
      //   var item = result.items;

      //   // Seems very dumb to calculate this on the front end.
      //   item.new_qty = Math.max(1, item.ITEM_QUANTITY - res.PREVIOUS_ITEM_QUANTITY);

      //   return item;
      // });

      this.setData({
        subtotal: result.trans.formattedSubtotal,
        points: result.trans.points === 0 ? 0 : result.trans.points || data.points,
        items: _normalizeResponseKeys(result.trans.order.items),
        item_count: result.trans.items_count,
        new_items: _normalizeResponseKeys(result.trans.order.samples)
      });

      // Temporarily set the added state:
      this.setState('added');
      state = 'added';
      closeCart = true;
      loaded = true;
      _setCloseTimeout();

      return this;
    },

    // Setters:
    setState: function (newState) {
      var states = ['empty', 'nonempty', 'added'];
      var classPrefix = 'cart-block--';
      var stateClasses = classPrefix + states.join(' ' + classPrefix);

      // If state is undefined, figure it out:
      if (_.isUndefined(newState)) {
        state = data.item_count > 0 ? 'nonempty' : 'empty';
        // Sanity check:
      } else if (!_.includes(states, newState)) {
        throw new Error('"' + newState + '" is not a valid cart state.');
      } else {
        state = newState;
      }

      $container.removeClass(stateClasses).addClass(classPrefix + state);

      return this;
    },

    setData: function (newData) {
      _.extend(data, newData);
      this.setState().render();

      return this;
    },

    // Getters:
    getState: function () {
      return state;
    },

    getData: function (key) {
      return _.isUndefined(key) ? data : data[key];
    },

    getResultType: function (results) {
      var type = 'sku';
      var isReplenishment = _.filter(results, function (result) {
        return result.instance === 'alter_replenishment' && result.type === 'REPL';
      });

      if (isReplenishment.length > 0) {
        type = 'replenishment';
      }

      return type;
    },

    displayNotificationMobile: function (message) {
      $cartNotificationContent.html(message);
      $cartNotification.removeClass('hidden');
      // Close notification after 3 seconds
      setTimeout(function () {
        $('.js-gnav-util-cart-notification-close').trigger('click');
      }, 3000);
    },

    displayNotificationPc: function (message) {
      var $msg = $(message);
      var $cartHeader = $cartContent.find('.js-gnav-util-cart-content-header');

      $msg.insertAfter($cartHeader);
    },

    setOverlayHeight: function () {
      var $headerMain = $('.js-site-header__wrapper', document);

      var siteHeaderHeight = $headerMain.outerHeight(true);
      var cartHeaderHeight = $cartContent
        .find('.js-gnav-util-cart-content-header')
        .outerHeight(true);
      var cartContentBottomHeight = $cartContent
        .find('.js-gnav-util-cart-content-products-bottom')
        .outerHeight(true);
      var sitewideBannerHeight = $('.js-site-header-formatter__top').outerHeight(true);
      var $cartProductsContainer = $cartContent.find('.js-gnav-util-cart-content-products-details');
      var overlayHeight = $(window).height() - siteHeaderHeight;
      var productsHeight =
        overlayHeight - cartHeaderHeight - cartContentBottomHeight - sitewideBannerHeight;

      // Set height of entire overlay to window height, less gnav offset.
      $cartContent.height(overlayHeight);
      // Set height of product list to available space.
      $cartProductsContainer.height(productsHeight);
    },

    updateCartCounter: function (item_count) {
      if (item_count > 0) {
        // Update cart counter in mobile or where is needed.
        setTimeout(function () {
          $(document).trigger('update_gnav_header_cart_counter', item_count);
        }, 2000);
        // Update cart counter in PC
        $counterInIcon.text(item_count);
        $container.addClass('cart-block--nonempty');
      }
    },

    openCartOverlay: function () {
      var $trigger = $('#gnav_util_trigger_cart', document);

      if (!isMobile) {
        $html.toggleClass('active-utility-overlay', true);
        $html.toggleClass('active-gnav', false);
        if ($trigger.prop('checked') === false) {
          $trigger.prop('checked', true);
        }
        behavior.setOverlayHeight();
      }
    },

    closeCartOverlay: function () {
      $html.toggleClass('active-utility-overlay', false);
      var $msg = $('.gnav-util-cart__content-errors', $cartContent);

      if ($msg.length > 0) {
        $msg.remove();
      }
      $(document).trigger('check_reset_gnav_radio_buttom');
    }
  };

  // Document listeners:
  $(document).on('offerToCart.success', function (event, result) {
    $cartContent.removeClass('hidden');
    behavior.addOffer(result);
  });

  $(document).on('addToCart.success', function (event, result) {
    behavior.addItem(result);
  });

  $(document).on(
    'addToCart.failure removeToCart.failure getItemsCart.failure',
    function (event, errors) {
      // Prepare the errors messages to be displayed
      var message = '<div class="gnav-util-cart__content-errors"><ul>';

      $.each(errors, function (key, value) {
        message += '<li>' + value['text'] + '</li>';
      });
      message += '</ul></div>';

      // Display errors below header title
      behavior.displayNotificationPc(message);
    }
  );

  $(document).on('loadCart.success', function () {
    behavior.load(true);
  });

  $(document).on('click', '.js-product-row-info-remove', function () {
    behavior.removeItem($(this));
  });

  $(document).on('click', '.js-gnav-util-cart-notification-close', function () {
    $cartNotification.addClass('hidden');
  });

  Unison.on('change', function () {
    bp = Unison.fetch.now();
    isMobile = parseInt(bp.width, 10) < parseInt(bps.landscape, 10);
  });

  return behavior;
})(
  (window.jQuery = window.jQuery || function () {}),
  (window._ = window._ || {}),
  (window.site = window.site || {}),
  (window.generic = window.generic || {}),
  (window.Mustache = window.Mustache || {})
);
